import http from "@/apps/shared/baseService/config";

const base = {
  rota: "posts",
  nome: "posts"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_posts: ({ commit, dispatch, getters }) => {
      // dispatch("enableLoading");
      service
        .listar(getters.get_posts_filtros)
        .then(res => {
          // dispatch("disableLoading");
          commit("set_posts", res.data);
        })
        .catch(e => {
          // dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_post: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_post, images: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_post", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_posts");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_post: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_post, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_post", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_posts");
          commit("set_modal_view_post", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_post: ({ dispatch, commit }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          commit("set_post", {});
          dispatch("listar_posts");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_posts: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_posts_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_posts");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_post: ({ commit, getters, dispatch }, payload) => {
      if (payload) {
        commit("set_post", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_post", true);
    },
    fecha_modal_view_post: ({ commit }) => {
      commit("set_post", {});
      commit("setLinks", []);
      commit("set_modal_view_post", false);
    },
    add_post_marcado: ({ commit, getters }, payload) => {
      commit("set_posts_marcados", [
        ...getters.get_posts_marcados,
        payload
      ]);
    },
    remove_post_marcado: ({ commit, getters }, i) => {
      commit(
        "set_posts_marcados",
        ...getters.get_posts_marcados.filter((item, index) => index !== i)
      );
    }
  },
  state: {
    post: {},
    posts: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    posts_marcados: [],
    posts_filtros: {
      limitPerPage: 6,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_post: false
  },
  mutations: {
    set_post: (state, payload) => (state.post = payload),
    set_posts: (state, payload) => (state.posts = payload),
    set_posts_marcados: (state, payload) =>
      (state.posts_marcados = payload),
    set_modal_view_post: (state, payload) =>
      (state.modal_view_post = payload)
  },
  getters: {
    get_post: state => state.post,
    get_posts: state => state.posts,
    get_posts_marcados: state => state.posts_marcados,
    get_modal_view_post: state => state.modal_view_post,
    get_posts_filtros: state => state.posts_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Listar.vue"),
    name: "Meus posts"
  }
];

export default {
  service,
  module,
  routes
};
