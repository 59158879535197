import http from "@/apps/shared/baseService/config";

const base = {
  rota: "produtos",
  nome: "produto"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_produtos: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_produtos_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_produtos", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_produto: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_produto, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_produto", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_produtos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_produto: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_produto, imagens: getters.getLinks })
        .then((res) => {
          dispatch("disableLoading");
          commit("set_produto", res);
          // commit("setLinks", []);
          console.log("res", res);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_produtos");
          commit("set_modal_view_produto", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_produto: ({ dispatch, commit }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          commit("set_produto", {
            opcionais: []
          });
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_produtos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_produtos: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_produtos_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_produtos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_produto: ({ commit, getters, dispatch }, payload) => {
      if (payload) {
        commit("set_produto", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_produto", true);
    },
    fecha_modal_view_produto: ({ commit }) => {
      commit("set_produto", {});
      commit("setLinks", []);
      commit("set_modal_view_produto", false);
    },
    add_produto_marcado: ({ commit, getters }, payload) => {
      commit("set_produtos_marcados", [...getters.get_produtos_marcados, payload]);
    },
    remove_produto_marcado: ({ commit, getters }, i) => {
      commit(
        "set_produtos_marcados",
        ...getters.get_produtos_marcados.filter((item, index) => index !== i)
      );
    }
  },
  state: {
    produto: {
      opcionais: []
    },
    produtos: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    produtos_marcados: [],
    produtos_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_produto: false
  },
  mutations: {
    set_produto: (state, payload) => (state.produto = payload),
    set_produtos: (state, payload) => (state.produtos = payload),
    set_produtos_marcados: (state, payload) => (state.produtos_marcados = payload),
    set_modal_view_produto: (state, payload) => (state.modal_view_produto = payload)
  },
  getters: {
    get_produto: state => state.produto,
    get_produtos: state => state.produtos,
    get_produtos_marcados: state => state.produtos_marcados,
    get_modal_view_produto: state => state.modal_view_produto,
    get_produtos_filtros: state => state.produtos_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Listar.vue"),
    name: base.nome
  },
  {
    path: `/menuonline`,
    component: () => import("./views/MenuOnline.vue"),
    name: "Categorias de Produtos"
  }
];

export default {
  service,
  module,
  routes
};
