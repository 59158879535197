<template>
  <img
    :style="`color: ${color}; width: ${size}px; height: ${size}px; background`"
    :src="`line/icons/${name}.svg`"
    :alt="name"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 30
    },
    color: {
      type: String,
      default: "#000"
    }
  }
};
</script>

<style scoped>
.icon {
  color: brown;
}
</style>
