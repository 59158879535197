import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { UserLoggedModule } from "@/apps/2#userLogged/store";
import { SharedModule } from "@/apps/shared/store";
import { AuthModule } from "@/apps/2#auth/store";
import Permissao from "./apps/1#Permissoes/meta";
import Funcionario from "./apps/9#Funcionarios/meta";
import Produto from "./apps/41#Produtos/meta";
import Caixa from "./apps/27#Caixas/meta";
import Cliente from "@/apps/44#Clientes/meta";
import LinkEQrcode from "@/apps/LinkEQrcode/meta";
import Fornecedor from "@/apps/Fornecedor/meta";
import GestorDePedido from "@/apps/GestorDePedido/meta";
import Lojas from "./apps/0#Lojas/meta";
import Insumos from "./apps/Insumos/meta";
import Transacoes from "./apps/Transacoes/meta";
import Report from "./apps/Relatorio/meta";
import Estoque from "./apps/20#Estoques/meta";
import Invoices from "./apps/46#Invoices/meta";
import Checkout from "./apps/Checkout/meta";
import MySubscription from "./apps/MySubscription/meta";
import Posts from "./apps/posts/meta";
import Lead from "./apps/Lead/meta";

export const store = new Vuex.Store({
  modules: {
    UserLoggedModule,
    AuthModule,
    SharedModule,
    permissao_module: Permissao.module,
    funcionarios_module: Funcionario.module,
    caixas_module: Caixa.module,
    produto: Produto.module,
    cliente: Cliente.module,
    mod_linkeqrcode: LinkEQrcode.module,
    mod_forn: Fornecedor.module,
    mod_gestor: GestorDePedido.module,
    mod_loja: Lojas.module,
    mod_insumos: Insumos.module,
    mod_transac: Transacoes.module,
    mod_estoque: Estoque.module,
    mod_invoice: Invoices.module,
    mod_checkout: Checkout.module,
    mod_subscription: MySubscription.module,
    mod_reports: Report.module,
    mod_posts: Posts.module,
    mod_lead: Lead.module
  }
});

export default store;
