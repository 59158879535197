import http from "@/apps/shared/baseService/config";

const base = {
  rota: "insumos",
  nome: "Insumos"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_insumos: ({ commit, dispatch, getters }) => {
      // dispatch("enableLoading");
      service
        .listar(getters.get_insumos_filtros)
        .then(res => {
          // dispatch("disableLoading");
          commit("set_insumos", res.data);
        })
        .catch(e => {
          // dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_insumo: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_insumo, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_insumo", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_insumos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_insumo: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_insumo, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_insumo", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_insumos");
          commit("set_modal_view_insumo", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_insumo: ({ dispatch, commit }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          commit("set_insumo", {});
          dispatch("listar_insumos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_insumos: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_insumos_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_insumos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_insumo: ({ commit, getters, dispatch }, payload) => {
      if (payload) {
        commit("set_insumo", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_insumo", true);
    },
    fecha_modal_view_insumo: ({ commit }) => {
      commit("set_insumo", {});
      commit("setLinks", []);
      commit("set_modal_view_insumo", false);
    },
    add_insumo_marcado: ({ commit, getters }, payload) => {
      commit("set_insumos_marcados", [
        ...getters.get_insumos_marcados,
        payload
      ]);
    },
    remove_insumo_marcado: ({ commit, getters }, i) => {
      commit(
        "set_insumos_marcados",
        ...getters.get_insumos_marcados.filter((item, index) => index !== i)
      );
    }
  },
  state: {
    insumo: {},
    insumos: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    insumos_marcados: [],
    insumos_filtros: {
      limitPerPage: 6,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_insumo: false
  },
  mutations: {
    set_insumo: (state, payload) => (state.insumo = payload),
    set_insumos: (state, payload) => (state.insumos = payload),
    set_insumos_marcados: (state, payload) =>
      (state.insumos_marcados = payload),
    set_modal_view_insumo: (state, payload) =>
      (state.modal_view_insumo = payload)
  },
  getters: {
    get_insumo: state => state.insumo,
    get_insumos: state => state.insumos,
    get_insumos_marcados: state => state.insumos_marcados,
    get_modal_view_insumo: state => state.modal_view_insumo,
    get_insumos_filtros: state => state.insumos_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Listar.vue"),
    name: "Estoques/Adcionais"
  }
];

export default {
  service,
  module,
  routes
};
