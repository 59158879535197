import axios from "axios";

let http = axios.create({
  // baseURL: process.env.VUE_APP_URL_API,
  baseURL: "https://api.idemsaude.com.br/cc/",
  // baseURL: "https://dstones.cosmoscode.com.br/prod/",
  // baseURL: "http://localhost:3010",
  // baseURL: "http://192.168.1.72:3010",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.token ? JSON.parse(localStorage.token) : ""
      }`
  }
});

http.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${localStorage.token ? JSON.parse(localStorage.token) : ""
      }`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default http;
