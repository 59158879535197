import http from "@/apps/shared/baseService/config";

const base = {
  rota: "linkeqrcodes",
  nome: "Link e QRCode"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_linkeqrcodes: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_linkeqrcodes_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_linkeqrcodes", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_linkeqrcode: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_linkeqrcode, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_linkeqrcode", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_linkeqrcodes");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_linkeqrcode: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_linkeqrcode, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_linkeqrcode", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_linkeqrcodes");
          commit("set_modal_view_linkeqrcode", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_linkeqrcode: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_linkeqrcode)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_linkeqrcodes");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_linkeqrcodes: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_linkeqrcodes_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_linkeqrcodes");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_linkeqrcode: ({ commit, getters, dispatch }) => {
      commit("set_modal_view_linkeqrcode", true);
    },
    fecha_modal_view_linkeqrcode: ({ commit }) => {
      commit("set_modal_view_linkeqrcode", false);
    },
    add_linkeqrcode_marcado: ({ commit, getters }, payload) => {
      commit("set_linkeqrcodes_marcados", [
        ...getters.get_linkeqrcodes_marcados,
        payload
      ]);
    },
    remove_linkeqrcode_marcado: ({ commit, getters }, i) => {
      commit(
        "set_linkeqrcodes_marcados",
        ...getters.get_linkeqrcodes_marcados.filter(
          (item, index) => index !== i
        )
      );
    }
  },
  state: {
    linkeqrcode: {},
    linkeqrcodes: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    linkeqrcodes_marcados: [],
    linkeqrcodes_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_linkeqrcode: false
  },
  mutations: {
    set_linkeqrcode: (state, payload) => (state.linkeqrcode = payload),
    set_linkeqrcodes: (state, payload) => (state.linkeqrcodes = payload),
    set_linkeqrcodes_marcados: (state, payload) =>
      (state.linkeqrcodes_marcados = payload),
    set_modal_view_linkeqrcode: (state, payload) =>
      (state.modal_view_linkeqrcode = payload)
  },
  getters: {
    get_linkeqrcode: state => state.linkeqrcode,
    get_linkeqrcodes: state => state.linkeqrcodes,
    get_linkeqrcodes_marcados: state => state.linkeqrcodes_marcados,
    get_modal_view_linkeqrcode: state => state.modal_view_linkeqrcode,
    get_linkeqrcodes_filtros: state => state.linkeqrcodes_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Listar.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
