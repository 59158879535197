import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import { dashboard_routes } from "./apps/dashboard/router";
import { logged_user_routes } from "./apps/2#userLogged/router";
import { auth_routes } from "./apps/2#auth/router";
import Permissao from "./apps/1#Permissoes/meta";
import Funcionario from "./apps/9#Funcionarios/meta";
import Caixa from "./apps/27#Caixas/meta";
import MenuOnline from "./apps/MenuOnline/meta";
import Cliente from "./apps/44#Clientes/meta";
import LinkEQrcode from "./apps/LinkEQrcode/meta";
import ConfigurarLoja from "./apps/ConfigurarLoja/meta";
import Fornecedor from "./apps/Fornecedor/meta";
import Relatorio from "./apps/Relatorio/meta";
import GestorDePedido from "./apps/GestorDePedido/meta";
import Lojas from "./apps/0#Lojas/meta";
import Insumo from "./apps/Insumos/meta";
import Transacoes from "./apps/Transacoes/meta";
import Invoices from "./apps/46#Invoices/meta";
import Checkout from "./apps/Checkout/meta";
import MySubscription from "./apps/MySubscription/meta";
import Posts from "./apps/posts/meta";
import Lead from "./apps/Lead/meta";

const routes = [
  ...dashboard_routes,
  ...logged_user_routes,
  ...auth_routes,
  ...Permissao.routes,
  ...Funcionario.routes,
  ...Caixa.routes,
  ...MenuOnline.routes,
  ...Cliente.routes,
  ...LinkEQrcode.routes,
  ...ConfigurarLoja.routes,
  ...Fornecedor.routes,
  ...Relatorio.routes,
  ...GestorDePedido.routes,
  ...Lojas.routes,
  ...Insumo.routes,
  ...Transacoes.routes,
  ...Invoices.routes,
  ...Checkout.routes,
  ...MySubscription.routes,
  ...Posts.routes,
  ...Lead.routes
];

export const router = new VueRouter({
  mode: process.env.CORDOVA_PLATFORM ? "hash" : "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
