import http from "@/apps/shared/baseService/config";

const base = {
  auth: "admin",
  rota: "invoices",
  name: "Contas"
};

export const service = {
  pay_invoice_charge: data => http.post(`/${base.auth}/${base.rota}/pay_invoice_charge`, data),
  criar: data => http.post(`/${base.auth}/${base.rota}/create`, data),
  listar: data => http.post(`/${base.auth}/${base.rota}/list`, data),
  atualizar: data => http.post(`/${base.auth}/${base.rota}/update`, data),
  excluir: data => http.post(`/${base.auth}/${base.rota}/delete`, data),
  excluir_muitos: data =>
    http.post(`/${base.auth}/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_invoices: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_invoices_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_invoices", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_invoice: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_invoice, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_invoice", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_invoices");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_invoice: ({ commit, dispatch, getters }) => {
      // dispatch("enableLoading");
      service
        .criar({ ...getters.get_invoice, archives: getters.getLinks })
        .then(() => {
          // dispatch("disableLoading");
          const invoice = { ...getters.get_invoice, saved: true };
          // commit("set_invoice", {
          //   saved: true
          // });
          commit("set_invoice", invoice);
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_invoices");
          return true;
        })
        .catch(e => {
          // dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
          return false;
        });
    },
    pay_invoice_charge: ({ dispatch, commit, getters }, payload) => {
      dispatch("enableLoading");
      service
        .pay_invoice_charge({
          charge: payload.charge,
          invoice: payload.invoice,
          arquivos: getters.getFiles
        })
        .then(() => {
          dispatch("disableLoading");
          commit("getFiles", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Conta atualizada para paga!",
            timeout: 6000
          });
          dispatch("listar_invoices");
          return true;
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
          return false;
        });
    },
    excluir_invoice: ({ dispatch, getters }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_invoices");
          dispatch("unsetConfirmAction");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    }
  },
  state: {
    invoice: {},
    invoices: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    invoices_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    }
  },
  mutations: {
    set_invoice: (state, payload) => (state.invoice = payload),
    set_invoices: (state, payload) => (state.invoices = payload)
  },
  getters: {
    get_invoice: state => state.invoice,
    get_invoices: state => state.invoices,
    get_invoices_filtros: state => state.invoices_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/List.vue"),
    name: base.name
  }
];

export default {
  service,
  module,
  routes
};
