import http from "@/apps/shared/baseService/config";
import { EventBus } from "@/main.js";

const base = {
  rota: "subscription",
  nome: "Minha Assinatura"
};

export const service = {
  listar: data => http.post(`/admin/${base.rota}/list`, data)
};

const module = {
  actions: {
    listar_subscriptions: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar({})
        .then(res => {
          dispatch("disableLoading");
          commit("set_subscriptions", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    }
  },
  state: {
    subscription: {}
  },
  mutations: {
    set_subscription: (state, payload) => (state.subscription = payload)
  },
  getters: {
    get_subscription: state => state.subscription
  }
};

const routes = [
  {
    path: `/minha-assinatura`,
    component: () => import("./views/Main.vue"),
    name: base.nome
  },
  {
    path: `/assinatura`,
    component: () => import("./views/MyStoreSubscription.vue"),
    name: base.nome
  },
];

export default {
  service,
  module,
  routes
};
