const routes = [
  {
    path: `/configurar-loja`,
    component: () => import("./views/Main.vue"),
    name: "Ajustes da Loja"
  }
];

export default {
  routes
};
