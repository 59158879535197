<template>
  <v-container style="flex-direction: column;" fluid class="molde pa-0 ma-0">
    <v-layout style="background: #fff;" column>
      <div class="expande-horizontal centraliza">
        <v-flex v-if="isAndroid" xs12>
          <div class="expande-horizontal">
            <v-app-bar
              :app="$vuetify.breakpoint.smAndDown"
              color="white"
              :fixed="$vuetify.breakpoint.smAndDown"
              class="elevation-0 pr-2"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'margin-bottom: 0px; border-radius: 0px'
                  : 'border-radius: 0px;margin-bottom: 0px'
              "
            >
              <v-btn dark @click="drawer = !drawer" icon>
                <v-icon :color="$theme.secondary">mdi-menu</v-icon>
              </v-btn>

              <span
                @click="drawer = !drawer"
                :class="{
                  'font-weight-bold': $vuetify.breakpoint.lgAndUp,
                  'fonte-little': $vuetify.breakpoint.lgAndUp,
                  'fonte-mini': $vuetify.breakpoint.smAndDown
                }"
                class="fonte-default text-capitalize"
                :style="`color: ${$theme.secondary}; cursor: pointer;`"
                >{{ title }}</span
              >

              <v-spacer></v-spacer>

              <v-menu left bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :color="$theme.secondary" icon v-on="on">
                    <v-icon size="21" :color="$theme.secondary"
                      >mdi-bell-outline</v-icon
                    >
                  </v-btn>
                </template>

                <v-list width="300">
                  <v-list-item
                    v-for="notification in []"
                    :key="notification._id"
                    @click="() => notification.action()"
                  >
                    <v-list-item-avatar>
                      <v-icon>mdi-bell-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="fonte">{{
                        notification.title
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="fonte">{{
                        notification.message
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="orange--text fonte"
                        style="font-size: 9pt;"
                        >{{
                          $moment(notification.created_at).format("llll")
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu v-if="$vuetify.breakpoint.lgAndUp" left bottom>
                <template v-slot:activator="{ on }">
                  <v-list color="transparent">
                    <v-list-item v-on="on">
                      <v-list-item-content>
                        <v-list-item-title
                          class="fonte default-font-color fonte-bold"
                        >
                          {{ getLoggedUser.activeTenant.nome }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar size="31" class="ml-3">
                          <v-img src="img/logo_mini.png"></v-img>
                        </v-avatar>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </template>

                <v-list nav dense>
                  <v-list-item to="/logged-user">
                    <v-list-item-title>{{
                      getLoggedUser.nome
                    }}</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item v-if="getLoggedUser.admin" to="/escolher-empresa">
                    <v-list-item-title>
                      Trocar de empresa
                    </v-list-item-title>
                  </v-list-item> -->
                  <v-list-item @click="logout">
                    <v-list-item-title>sair</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu v-else left bottom>
                <template v-slot:activator="{ on }">
                  <v-avatar v-on="on" size="31" class="ml-5">
                    <v-img v-if="getLoggedUser.photo.length" :src="getLoggedUser.photo[0]"></v-img>
                    <v-img v-else src="img/logo_mini.png"></v-img>
                  </v-avatar>
                </template>

                <v-list class="fonte" nav dense>
                  <v-list-item to="/logged-user">
                    <v-list-item-title>{{
                      getLoggedUser.nome
                    }}</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item v-if="getLoggedUser.admin" to="/escolher-empresa">
                    <v-list-item-title>
                      Trocar de empresa
                    </v-list-item-title>
                  </v-list-item> -->
                  <v-list-item @click="logout">
                    <v-list-item-title>sair</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu v-show="options" offset-y left bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-show="options && $vuetify.breakpoint.smAndDown"
                    icon
                    :color="$theme.secondary"
                    v-on="on"
                  >
                    <v-icon :color="$theme.secondary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="item in options"
                    :key="item.nome"
                    @click="() => item.action()"
                  >
                    <v-list-item-title class="fonte">{{
                      item.nome
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-app-bar>
          </div>
        </v-flex>
        <v-flex v-else xs12 md10>
          <div
            class="expande-horizontal"
            :style="
              `${$vuetify.breakpoint.mdAndDown ? '' : 'padding-left: 50px;'}`
            "
          >
            <v-app-bar
              :app="$vuetify.breakpoint.smAndDown"
              color="white"
              :fixed="$vuetify.breakpoint.smAndDown"
              class="elevation-0"
              dense
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'margin-bottom: 0px; border-radius: 0px'
                  : 'border-radius: 0px;margin-bottom: 0px'
              "
            >
              <v-btn dark @click="drawer = !drawer" icon>
                <v-icon size="18" :color="$theme.secondary">mdi-menu</v-icon>
              </v-btn>

              <!-- <v-btn dark v-if="this.icon !== undefined" @click="goBack()" icon>
                <v-avatar class="elevation-0">
                  <v-icon color="#333"> mdi-checron-left </v-icon>
                </v-avatar>
              </v-btn> -->

              <span
                @click="drawer = !drawer"
                :class="{
                  'font-weight-bold': $vuetify.breakpoint.lgAndUp,
                  'fonte-little': $vuetify.breakpoint.lgAndUp,
                  'fonte-mini': $vuetify.breakpoint.smAndDown
                }"
                class="fonte-default text-capitalize"
                :style="`color: ${$theme.secondary}; cursor: pointer;`"
                >{{ title }}</span
              >
              <!-- <span class="fonte ml-2 fonte-bold grey--text"> Bem Vindo(a) </span> -->

              <!-- <v-spacer></v-spacer> -->

              <!-- <v-flex class="px-2" md3>
                  <v-text-field hide-details solo-inverted dark flat dense v-model="pesquisa" placeholder="Faça uma busca" :color="$theme.primary" append-icon="mdi-magnify"></v-text-field>
              </v-flex> -->

              <v-spacer></v-spacer>

              <!-- <v-menu bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :color="$theme.primary" icon v-on="on">
                    <v-icon size="21" :color="$theme.icon_color"
                      >mdi-bell-outline</v-icon
                    >
                  </v-btn>
                </template>
  
                <v-list width="300">
                  <v-list-item
                    v-for="notification in getNotifications.received"
                    :key="notification._id"
                    @click="() => item.action()"
                  >
                    <v-list-item-avatar>
                      <v-icon>mdi-bell-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="fonte">{{
                        notification.title
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="fonte">{{
                        notification.message
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="orange--text fonte"
                        style="font-size: 9pt;"
                        >{{
                          $moment(notification.created_at).format("llll")
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu> -->

              <v-menu left bottom>
                <template v-slot:activator="{ on }">
                  <v-list color="transparent">
                    <v-list-item v-on="on">
                      <v-list-item-content>
                        <v-list-item-title
                          class="fonte default-font-color fonte-bold"
                        >
                          {{ getLoggedUser.activeTenant.nome }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar size="31" class="ml-3">
                          <v-img v-if="getLoggedUser.photo.length" :src="getLoggedUser.photo[0]"></v-img>
                          <v-img v-else src="img/logo_mini.png"></v-img>
                        </v-avatar>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </template>

                <v-list class="fonte" nav dense>
                  <v-list-item to="/logged-user">
                    <v-list-item-title>{{
                      getLoggedUser.nome
                    }}</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item v-if="getLoggedUser.admin" to="/escolher-empresa">
                    <v-list-item-title>
                      Trocar de empresa
                    </v-list-item-title>
                  </v-list-item> -->
                  <v-list-item @click="logout">
                    <v-list-item-title>sair</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu v-show="options" left bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-show="options && $vuetify.breakpoint.smAndDown"
                    icon
                    :color="$theme.primary"
                    v-on="on"
                  >
                    <v-icon :color="$theme.secondary"
                      >mdi-dots-vertical</v-icon
                    >
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="item in options"
                    :key="item.nome"
                    @click="() => item.action()"
                  >
                    <v-list-item-title class="fonte">{{
                      item.nome
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-app-bar>
          </div>
        </v-flex>
      </div>

      <v-navigation-drawer
        class="drawer-roller-style"
        v-if="$vuetify.breakpoint.lgAndUp"
        :mini-variant="
          !isAndroid || !$vuetify.breakpoint.smAndDown ? true : false
        "
        :expand-on-hover="
          !isAndroid || !$vuetify.breakpoint.xsOnly ? true : false
        "
        app
        :temporary="isAndroid || $vuetify.breakpoint.xsOnly ? true : false"
        v-model="drawer"
      >
      <v-card
          class="elevation-0"
          color="transparent"
          height="64px"
          style="border-radius: 0px; margin-bottom: 0px;"
        >
          <!-- <span>{{ $vuetify.breakpoint }}</span> -->
          <div class="semQuebra pa-3 pr-4 pl-4  centraliza expande-horizontal">
            <!-- <img style="width: 90%;" src="img/logo.png" alt="" /> -->
            <img style="width: 50px;" src="img/logo_mini.png" alt="" />
            <!-- <img style="width: 40%;" :src="$theme.logo" alt="" /> -->
          </div>
        </v-card>

        <v-list style="height: 74vh;" nav dense>
          <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/posts"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="
                $route.path === '/posts' ? 'white' : $theme.icon_drawer
              "
            >
              mdi-ballot-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/posts' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Posts
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/leads"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="
                $route.path === '/leads' ? 'white' : $theme.icon_drawer
              "
            >
              mdi-chart-box-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/leads' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Leads
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/logged-user" class="mt-6">
            <v-avatar size="27" class="mr-2" v-if="getLoggedUser.photo.length">
              <v-img
                :src="getLoggedUser.photo.length ? getLoggedUser.photo[0] : ''"
              ></v-img>
            </v-avatar>
            <v-icon size="21" class="mr-3" :color="$theme.icon_drawer" v-else
              >mdi-account-circle</v-icon
            >
            <v-list-item-content class="ml-2">
              <v-list-item-title class="fonte">
                {{ getLoggedUser.nome }}
              </v-list-item-title>
              <v-list-item-subtitle class="fonte">
                {{ getLoggedUser.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-navigation-drawer
        v-else
        app
        v-model="drawer"
      >
        <v-card
          class="elevation-0"
          color="transparent"
          style="border-radius: 0px; margin-bottom: 0px;"
        >
          <!-- <span>{{ $vuetify.breakpoint }}</span> -->
          <div class="semQuebra pa-3 pr-4 pl-4  centraliza expande-horizontal">
            <!-- <img style="width: 90%;" src="img/logo.png" alt="" /> -->
            <img style="width: 50px;" src="img/logo_mini.png" alt="" />
            <!-- <img style="width: 40%;" :src="$theme.logo" alt="" /> -->
          </div>
        </v-card>

        <v-list style="height: 74vh;" nav dense>
          <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/posts"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="
                $route.path === '/posts' ? 'white' : $theme.icon_drawer
              "
            >
              mdi-chart-box-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/posts' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Posts
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mb-6 mt-4"
            active-class="menuAtivoHome"
            to="/leads"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="
                $route.path === '/leads' ? 'white' : $theme.icon_drawer
              "
            >
              mdi-chart-box-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/leads' ? 'white--text' : 'font-color'
                "
                class="fonte"
              >
                Leads
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/logged-user" class="mt-6">
            <v-avatar size="27" class="mr-2" v-if="getLoggedUser.photo.length">
              <v-img
                :src="getLoggedUser.photo.length ? getLoggedUser.photo[0] : ''"
              ></v-img>
            </v-avatar>
            <v-icon size="21" class="mr-3" :color="$theme.icon_drawer" v-else
              >mdi-account-circle</v-icon
            >
            <v-list-item-content class="ml-2">
              <v-list-item-title class="fonte">
                {{ getLoggedUser.nome }}
              </v-list-item-title>
              <v-list-item-subtitle class="fonte">
                {{ getLoggedUser.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main class="molde" :style="`background: ${$theme.background};`">
        <div class="expande-horizontal centraliza">
          <v-flex v-if="isAndroid" xs12>
            <div class="expande-horizontal wrap">
              <div
                v-if="$vuetify.breakpoint.smAndUp"
                class="expande-horizontal px-2 py-1"
              >
                <span
                  v-for="(rota, index) in rotas"
                  @click="$router.push(rota.path)"
                  :key="rota.path"
                  :style="`font-size: 12pt; cursor: pointer;`"
                  class="fonte-subtitulo fonte-italica pa-4 pr-0 pt-0"
                >
                  {{ rota.name }}
                  <v-icon
                    class="pl-3"
                    v-if="index < rotas.length - 1"
                    size="13"
                  >
                    mdi-chevron-right
                  </v-icon>
                </span>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  v-for="item in options"
                  :color="$theme.secondary"
                  class="elevation-0 animated flipInX delay-1s mr-1"
                  @click="item.action()"
                  :key="item.nome"
                >
                  <v-icon size="13" class="mr-1" color="#fff">
                    {{ item.icon }}
                  </v-icon>
                  <span
                    class="fonte white--text text-capitalize font-weight-bold"
                    >{{ item.nome }}</span
                  >
                </v-btn>
              </div>
              <slot></slot>
            </div>
          </v-flex>
          <v-flex v-else xs12 md10>
            <div class="expande-horizontal wrap">
              <div
                v-if="$vuetify.breakpoint.smAndUp"
                class="expande-horizontal px-2 py-1"
              >
                <span
                  v-for="(rota, index) in rotas"
                  @click="$router.push(rota.path)"
                  :key="rota.path"
                  :style="`font-size: 12pt; cursor: pointer;`"
                  class="fonte-subtitulo fonte-italica pa-4 pr-0 pt-0"
                >
                  {{ rota.name }}
                  <v-icon
                    class="pl-3"
                    v-if="index < rotas.length - 1"
                    size="13"
                  >
                    mdi-chevron-right
                  </v-icon>
                </span>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  v-for="item in options"
                  :color="$theme.secondary"
                  class="elevation-0 animated flipInX delay-1s mr-1"
                  @click="item.action()"
                  :key="item.nome"
                >
                  <v-icon size="13" class="mr-1" color="#fff">
                    {{ item.icon }}
                  </v-icon>
                  <span
                    class="fonte white--text text-capitalize font-weight-bold"
                    >{{ item.nome }}</span
                  >
                </v-btn>
              </div>
              <slot></slot>
            </div>
          </v-flex>
        </div>
      </v-main>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["title", "options", "icon", "action", "origem", "rotas"],
  data() {
    return {
      drawer: false,
      getNotifications: {
        received: []
      },
      isAndroid: false
    };
  },
  computed: {
    ...mapGetters([
      "getLoggedUser",
      "get_permission_user"
      // 'getNotifications'
    ])
  },
  methods: {
    ...mapActions([
      // 'listNotifications',
      "logout"
    ]),
    listNotifications() {},
    goBack() {
      if (this.action !== undefined) {
        this.action();
      } else {
        this.$router.go(-1);
      }
    }
  },
  created() {
    this.drawer = !this.isAndroid;
    this.listNotifications();
    this.isAndroid = navigator.userAgent.match(/Android/i) || this.$vuetify.breakpoint.smAndDown;
    // console.log("asd", this.$vuetify);

    let cordova = window.cordova || false;
    const self = this;
    if (cordova) {
      cordova.plugins.firebase.messaging
        .requestPermission({ forceShow: false })
        .then(function() {
          console.log("Push messaging is allowed");
          cordova.plugins.firebase.messaging
            .getToken()
            .then(function(token) {
              self.$db.ref(`pn/${self.getLoggedUser._id}`).set({
                token: token,
                user: self.getLoggedUser._id
              });
            })
            .catch(function(err) {
              alert(err);
            });
        });
      StatusBar.backgroundColorByHexString(this.$theme.secondary);
    }
    if (this.$vuetify.breakpoint.smAndDown) {
      this.drawer = false;
    }
  }
};
</script>

<style>
.centraliza-tudo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.molde {
  display: flex;
  width: 100%;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  width: 1px; /* width of the entire scrollbar */
}

.v-navigation-drawer__content::-webkit-scrollbar-track {
  background: rgb(122, 122, 122); /* color of the tracking area */
  border-radius: 6px;
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background-color: #e5e7e8; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
</style>
