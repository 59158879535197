import Vue from "vue";
import store from "@/store";
import App from "./App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import mask from "vue-the-mask";
import moment from "moment";
import "moment/locale/pt-br";
import "@/apps/shared";
import { db, storage } from "./apps/shared/firebase";
import run from "./apps/shared/Onoff";
import LineIcon from "./apps/shared/components/LineIcon";
import Helper from "./helper";
import VueHtml2pdf from "vue-html2pdf";
import VuejsClipper from "vuejs-clipper";
import VueCropper from "vue-cropper";
import VueFroala from 'vue-froala-wysiwyg'
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/languages/pt_br.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/themes/dark.css";
Vue.use(VueFroala)

Vue.use(VueCropper);

// import "vue-roller/dist/style.css";

export const EventBus = new Vue();

// Vue.use(VueRoller);
Vue.use(VueHtml2pdf);
Vue.use(mask);
Vue.use(VuejsClipper);
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;

const theme = {
  primary: "#AB47BC",
  secondary: "#AB47BC",
  // secondary: "#5E35B1",
  // secondary: "#3CACBA",
  third: "#AB47BC",
  icon_drawer: "#AB47BC",
  icon_color: "#AB47BC",
  background: "#fff",
  grey: "#f2f2f2",
  drawer: "#010f50",
  logo:
    "https://upload.wikimedia.org/wikipedia/commons/d/d4/ROCKET_logo.svg",
  token_img: ""
};

Vue.component("icon", LineIcon);

Vue.prototype.$theme = theme;
Vue.prototype.$db = db;
Vue.prototype.$storage = storage;
Vue.prototype.$helper = new Helper();
Vue.prototype.$run = run;

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
