import { router } from "@/router";
import LoginService from "../service";

export const actions = {
  createSession({ commit, dispatch }, dados) {
    dispatch("enableLoading");

    LoginService.auth(dados)
      .then(res => {
        dispatch("disableLoading");
        commit("setLoggedUser", res.data.user);
        commit("setToken", res.data.token);
        localStorage.user = JSON.stringify(res.data.user);
        localStorage.token = JSON.stringify(res.data.token);
        router.push("/posts");
        // router.push(res.data.user.permissao.rota_inicial.rota);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 3000
        });
      });
  },
  createUser({ commit, dispatch }, dados) {
    dispatch("enableLoading");
    LoginService.signup(dados)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Cadastrado com sucesso!",
          timeout: 3000
        });
        router.push("/");
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 3000
        });
      });
  },
  async verifySponsor(state, payload) {
    const sponsor = await LoginService.verifySponsor(payload);
    state.commit("setSponsor", sponsor.data);
    return sponsor.data;
  },
  setModalForgotPassword({ commit, getters }, payload) {
    commit("setModalForgotPassword", payload);
  },
  async check_permission({ getters }, payload) {
    const permissions = getters.getLoggedUser.permissao.permissoes;

    let granted = permissions.includes(payload);

    console.log("granted", granted);

    return granted;
  },
  sendForgotPassword({ commit, dispatch }, email) {
    dispatch("enableLoading");
    LoginService.forgotPassword({ email })
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "O email de recuperação foi enviado!",
          timeout: 3000
        });
        commit("setModalForgotPassword", false);
        router.push("/");
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 3000
        });
      });
  },
  sendForgotPasswordFire({ commit, dispatch }, payload) {
    dispatch("enableLoading");
    LoginService.forgotPasswordFire(payload)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Sua senha foi redefinida com sucesso!",
          timeout: 3000
        });
        router.push("/");
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 3000
        });
      });
  },
  activateAccount({ commit, dispatch }, payload) {
    dispatch("enableLoading");
    LoginService.activateAccount(payload)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Sua conta foi ativada com sucesso!",
          timeout: 3000
        });
        router.push("/");
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 3000
        });
      });
  },
  set_empresa_ativa({ commit }, payload) {
    localStorage.user = JSON.stringify(payload);
    commit("setEmpresaAtiva", payload);
  },
  logout({ commit }) {
    localStorage.clear();
    commit("setLoggedUser", false);
    commit("setToken", false);
    commit("set_empresa_ativa", false);
    router.push("/");
  }
};

export default {};
